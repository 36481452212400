import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isLoogedInThunk, selectUser } from "../store/AuthSlice";
import {
  carouselThunk,
  pricesThunk,
  selectPictures,
  selectPrices,
  selectSpecialPrices,
} from "./../store/HomeSlice";
import Carousel from "react-material-ui-carousel";
import "../Styles/homepage.scss";
import whiteBox from "../assets/white-box2.png";
import { Grid } from "@mui/material";
import cut from "../assets/cut.jpg";
import beard from "../assets/beard.jpg";
import fade from "../assets/fade-cut.jpg";
import abovePrice from "../assets/above-price.jpg";

const Homepage = () => {
  const dispatch = useDispatch();

  const pictures = useSelector(selectPictures);
  // const user = useSelector(selectUser);
  const prices = useSelector(selectPrices);
  const specialPrices = useSelector(selectSpecialPrices);

  useEffect(() => {
    // dispatch(isLoogedInThunk());
    dispatch(carouselThunk());
    dispatch(pricesThunk());
  }, []);

  return (
    <div className="home">
      {/* Carousel section */}
      <Carousel
        indicators={false}
        animation="fade"
        swipe={false}
        autoPlay={true}
        duration={1000}
        interval={5000}
      >
        {pictures.map((pic, i) => (
          <CarouselItem key={i} item={pic} />
        ))}
      </Carousel>
      {/* Bottom section */}
      <Grid container direction="column" alignContent="center">
        {/* Prices */}
        <Grid container direction={"column"} alignContent="center"  style={{paddingInline: "20px"}}>
          <Grid item className="verticalCenter" style={{ width: "100%" }}>
            <h4>Cenovnik</h4>
            {prices?.map((price) => (
              <PriceItem item={price} key={price.pr_id} />
            ))}
          </Grid>
          <Grid item className="verticalCenter" style={{ width: "100%" }}>
            <h4>Specijalna ponuda</h4>
            {specialPrices?.map((price) => (
              <SpecialPriceItem item={price} key={price.spr_id} />
            ))}
          </Grid>
        </Grid>
        {/* Banner */}
        <img src={abovePrice} alt="banner" className="banner" />

        {/* Circled images section */}
        <Grid direction={"column"}>
          <h2 style={{textAlign: "center"}}>Usluge</h2>
          <Grid container justifyContent="space-around">
            <Grid item className="verticalCenter">
              <img className="roundedImage" src={cut} alt="Šišanje" />{" "}
              <h3>Šišanje</h3>
            </Grid>
            <Grid item className="verticalCenter">
              <img
                className="roundedImage"
                src={beard}
                alt="Trimovanje brade"
              />{" "}
              <h3>Trimovanje brade</h3>
            </Grid>
            <Grid item className="verticalCenter">
              <img className="roundedImage" src={fade} alt="Fade cut" />{" "}
              <h3>Fade cut</h3>
            </Grid>
          </Grid>
        </Grid>

        {/* Working hours */}
        <div className="hours">
          <div className="hoursOpacity">
            <h2>Radno vreme</h2>
            <div className="hoursWrapper">
              <div className="day">
                <h3>Ponedeljak - Petak</h3>
                <p>11h - 15h</p>
                <p>16h - 20h</p>
              </div>
              <div className="day">
                <h3>Subota</h3>
                <p>11h - 15h</p>
              </div>
              <div className="day">
                <h3>Nedelja</h3>
                <p>Neradan dan!</p>
              </div>
            </div>
          </div>
        </div>
      </Grid>
    </div>
  );
};

export default Homepage;

function CarouselItem({ item }) {
  return (
    <div>
      <img
        style={{
          width: "100%",
          height: "100vh",
          objectPosition: "50%",
          objectFit: "cover",
        }}
        src={item.car_image}
        alt="image"
      />
    </div>
  );
}

function PriceItem({ item }) {
  return (
    <div className="priceData">
      <h3>{item.pr_name}</h3> <p>{item.pr_amount} rsd</p>
    </div>
  );
}

function SpecialPriceItem({ item }) {
  return (
    <div className="priceData">
      <h3>{item.spr_name}</h3> <p>{item.spr_amount} rsd</p>
    </div>
  );
}
