import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { carousel, prices } from "../Api/auth";
import { localStorageSet, localStorageRemove } from "../Utils/localStorage";
import { checkEmptyObject } from "../Utils/utils";

export const carouselThunk = createAsyncThunk("carousel", async () => {
  const response = await carousel();

  return response;
});

export const pricesThunk = createAsyncThunk("prices", async () => {
  const response = await prices();

  return response;
});

export const selectPictures = (state) => state?.home?.pictures;
export const selectPrices = (state) => state?.home?.prices;
export const selectSpecialPrices = (state) => state?.home?.specialPrices;

const initialState = {
  pictures: [],
  prices: [],
  specialPrices: [],
};

export const HomeSlice = createSlice({
  name: "home",
  initialState: initialState,
  extraReducers: (builder) => {
    builder.addCase(carouselThunk.fulfilled, (state, action) => {
      state.pictures = action.payload.pictures;
    });
    builder.addCase(pricesThunk.fulfilled, (state, action) => {
      state.prices = action.payload.prices;
      state.specialPrices = action.payload.specialPrices;
    });
  },
});
