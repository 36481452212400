import logo from "./logo.svg";
import "./App.scss";
// to import fonts loacaly
import "./fonts/Montserrat-Regular.ttf";

import { BrowserRouter as Router, Route, Link, Switch } from "react-router-dom";
import Homepage from "./Pages/Homepage";
import Login from "./Pages/Login";
import About from "./Pages/About";
import Header from "./Components/Header";
import Cupons from "./Pages/Cupons";
import LuckyPage from "./Pages/LuckyPage";
import Users from "./Pages/Users";
import Register from "./Pages/Register";
import Footer from "./Components/Footer";

function App() {
  return (
    <Router>
      <Header />
      <Switch>
        <Route exact path="/">
          <Homepage />
        </Route>
        <Route exact path="/about">
          <About />
        </Route>
        {/* <Route exact path="/login">
          <Login />
        </Route> */}
      </Switch>
      <Footer />
    </Router>
  );
}

export default App;
