import React, { useEffect, useRef, useState } from "react";
import { checkEmptyObject } from "../Utils/utils";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { selectUser, isLoogedInThunk } from "../store/AuthSlice";

export default (WrappedComponent) => {
  const HocComponent = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const user = useSelector(selectUser);

    const [first, setfirst] = useState(0);

    useEffect(() => {
      dispatch(isLoogedInThunk());
    }, []);

    useEffect(() => {
      if (first < 2 && user == null) {
        setTimeout(() => {
          setfirst((prev) => prev + 1);
        }, 100);
        return;
      }

      if (!user) {
        dispatch(isLoogedInThunk());
        history.push("/");
      }
    }, [user, first]);

    return <WrappedComponent />;
    // return checkEmptyObject(user) ? <WrappedComponent /> : null;
  };

  return HocComponent;
};
