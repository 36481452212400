import React from "react";
import "../Styles/Header.scss";
import { AppBar, Button, Link, Toolbar, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

import { NavLink } from "react-router-dom";

import logo from "../assets/logo.png";
import { logOutThunk, selectUser } from "../store/AuthSlice";
import { checkEmptyObject } from "./../Utils/utils";

const Header = () => {
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const isLogged = checkEmptyObject(user);
  const isAdmin = checkEmptyObject(user) ? user.usr_admin : false;
  const isActivated = checkEmptyObject(user) ? user.usr_activated : true;

  return (
    <AppBar
      position="static"
      // color="default"
      elevation={0}
      sx={{ borderBottom: (theme) => `1px solid ${theme.palette.divider}` }}
    >
      <Toolbar sx={{ flexWrap: "wrap", justifyContent: "space-between" }}>
        <img src={logo} style={{ maxWidth: "50px" }} />
        <nav>
          <Link
            variant="button"
            color="text.primary"
            href="#"
            sx={{ my: 1, mx: 1.5, textDecorationLine: "none" }}
          >
            <NavLink className="link" exact to={"/"}>
              Početna
            </NavLink>
          </Link>
          <Link
            variant="button"
            color="text.primary"
            href="#"
            sx={{ my: 1, mx: 1.5, textDecorationLine: "none" }}
          >
            <NavLink className="link" exact to={"/about"}>
              O salonu
            </NavLink>
          </Link>
          {isLogged && isActivated && (
            <Link
              variant="button"
              color="text.primary"
              href="#"
              sx={{ my: 1, mx: 1.5, textDecorationLine: "none" }}
            >
              <NavLink
                className="link"
                exact
                onClick={() => dispatch(logOutThunk())}
                to={"/"}
              >
                Odjava
              </NavLink>
            </Link>
          )}
        </nav>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
