import React from "react";
import owner from "../assets/owner.jpg";
import logo from "../assets/logo-in-picture.png";
import "../Styles/About.scss";

const About = () => {
  return (
    <div className="wrapper">
      <h1 style={{ textAlign: "center" }}>
        Dobrodošli u muški frizerski salon "Nemus in style"
      </h1>

      <figure>
        <img src={owner} alt="Nemanja Savić" />
        <figcaption>Osnivač i vlasnik Nemanja Savić</figcaption>
      </figure>

      <div className="introduction">
        <h2>Reči vlasnika</h2>
        <p>
          Još kao dečak sam zavoleo frizerski posao, posmatrajući moju baku Nadu
          kako to radi. Ceo život sam maštao da imam svoj salon. Konačno mi se
          taj san ostvaruje. Kada sam započeo ceo proces otvaranja, sve je
          izgledalo tako jednostavno, ali zapravo uopšte nije. Evo sada sam
          došao do svog sna uz pomoć i podršku svoje porodice. Hvala im na tome!
          Oni su moj vetar u leđa!
        </p>
        <p>
          Roditelji Vladimir i Dragana su mi moralna i finansijska podrška, a
          braća Nikola i Nebojša svojim kreativnim idejama doprinose čitavom
          konceptu i realizaciji ovog projekta.
        </p>
        <p>
          Naravno, ja sam morao da ostavim svoj lični pečat na celu zamisao.
        </p>
        <p>
          Najveću zahvalnost za ceo trud dugujem najmlađem bratu Nebojši. On je
          ujedno i autor kompletnog sajta. Salon je osnovan 9.7.2021. godine na
          moj 28. rođendan, te mi je to i najveći poklon.
        </p>
        <p>
          Ovo je ukratko put do mog sna "Nemus in style" pomalo neobičnog naziva
          koji smo odabrali uz pomoć mojih prijatelja, te i njima dugujem
          zahvalnost!
        </p>
        <h3>Dobrodošli u moj salon na adresi Stevana Sremca 11 u Kuli!</h3>
      </div>

      <img id="logoInPicture" src={logo} alt="Logo" />
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d414.8861355119715!2d19.53384175396362!3d45.610440752548975!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x475b5743ec973abb%3A0xfd0cf5e3bef1ba9e!2sStevana%20Sremca%2011%2C%20Kula!5e0!3m2!1ssr!2srs!4v1624614619879!5m2!1ssr!2srs"
        width="100%"
        height="350"
        style={{ border: 0 }}
        allowfullscreen=""
        loading="lazy"
      ></iframe>
    </div>
  );
};

export default About;
