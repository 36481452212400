export const localStorageGet = (key) => {
  try {
    return JSON.parse(localStorage.getItem(key));
  } catch (e) {
    return null;
  }
};

export const localStorageSet = (key, value) => {
  try {
    localStorage.setItem(key, JSON.stringify(value));
  } catch (e) {
    // Do nothing
  }
};

export const localStorageRemove = (key) => {
  try {
    localStorage.removeItem(key);
  } catch (e) {
    // Do nothing
  }
};

export const localStorageClear = () => {
  try {
    localStorage.clear();
  } catch (e) {
    // Do nothing
  }
};

