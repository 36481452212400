import React from "react";
import "../Styles/Footer.scss";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import PhoneIcon from "@mui/icons-material/Phone";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import EmailIcon from "@mui/icons-material/Email";

const Footer = () => {
  const year = new Date().getFullYear()
  return (
    <footer>
      <div className="footerTop">
        <div className="footerSection">
          <h3>Nemanja Savić</h3>
          <p className="data">
            <PhoneIcon />
            061 184-3435
          </p>
          <p className="data">
            <LocationOnIcon /> Stevana Sremca 11, Kula
          </p>
          <p className="data">
            <EmailIcon /> &nbsp; savicnemanja01@gmail.com
          </p>

          <div className="socials">
            <a
              target="_blank"
              href="https://www.facebook.com/nemanjanelle.savic"
            >
              <FacebookIcon />
            </a>

            <a target="_blank" href="https://www.instagram.com/nemus0907/">
              <InstagramIcon />
            </a>
          </div>
        </div>
      </div>
      <div className="footerBottom">
        <p>copyright &copy; {year} Nemanja Savić</p>
      </div>
    </footer>
  );
};

export default Footer;
