import { localStorageGet } from "../Utils/localStorage";
import axios from "./index";

export const isLogged = () => {
  return axios
    .get("auth/isLogged", { params: { sid: localStorageGet("sid") } })
    .then((res) => res.data);
};

export const login = ({ email, password }) => {
  return axios.post("auth/login", { email, password }).then((res) => res.data);
};

export const logOut = () => {
  return axios
    .get("auth/logout", { params: { sid: localStorageGet("sid") } })
    .then((res) => res.data);
};

export const carousel = () => {
  return axios.get("carousel").then((res) => res.data);
};

export const prices = () => {
  return axios.get("pricing").then((res) => res.data);
};
