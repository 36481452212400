import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { isLogged, login, logOut } from "../Api/auth";
import { localStorageSet, localStorageRemove } from "../Utils/localStorage";
import { checkEmptyObject } from "../Utils/utils";

export const loginThunk = createAsyncThunk(
  "login",
  async ({ email, password }) => {
    const response = await login({ email, password });

    return response;
  }
);

export const isLoogedInThunk = createAsyncThunk("isLogged", async () => {
  const response = await isLogged();

  return response;
});

export const logOutThunk = createAsyncThunk("logout", async () => {
  const response = await logOut();

  return response;
});

export const selectUser = (state) => state.auth.user;

const initialState = {
  user: null,
};

export const AuthSlice = createSlice({
  name: "auth",
  initialState: initialState,
  extraReducers: (builder) => {
    builder.addCase(loginThunk.fulfilled, (state, action) => {
      localStorageSet("sid", action.payload.sid);
      state.user = action.payload.user;
    });
    builder.addCase(loginThunk.rejected, (state) => {
      state.user = null;
    });
    builder.addCase(isLoogedInThunk.fulfilled, (state, action) => {
      if (checkEmptyObject(action.payload.user)) {
        state.user = action.payload.user;
      }
    });
    builder.addCase(logOutThunk.fulfilled, (state, action) => {
      state.user = null;
      localStorageRemove("sid");
    });
  },
});
